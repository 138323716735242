<template>
  <v-row class="teal lighten-2 pa-2 rounded mb-0 white--text">
    <v-col cols="2">
      Всего товаров: {{ wares.length }}
    </v-col>
    <v-col
      cols="8"
    >
      Контракт {{ contract }}
    </v-col>
    <v-col cols="2">
      Сумма: {{ totalCost }}
    </v-col>
  </v-row>
</template>
<script>


import {getDocumentString} from "@/helpers/text";

export default {
  props:{
    wares:{
      required:true,
      type:Array
    },
    declaration:{
      required:true,
      type:Object
    },
  },
  computed:{
    typeDeclaration(){
      return this.declaration?.type_declaration?.declaration_kind_code
    },
    contract(){
      const {doc_date, doc_number} = this.declaration?.presented_documents?.find(i => i.doc_code === '03011') ?? {}
      return getDocumentString(doc_date, doc_number)
    },
    totalCost(){
      const {invoice_cost, invoice_currency_letter} = this.declaration?.deal_details?.deal_details_json ?? {}
      return `${invoice_cost ? parseFloat(invoice_cost).toLocaleString().replace(',', '.') : 0} ${invoice_currency_letter ? invoice_currency_letter : ''}`
    }
  }
}
</script>
