import {downloadXml, downloadFile, getFilenameFromContentDisposition } from '@/helpers/documents'

const actions = [
  {
    icon: "mdi-file-pdf-box",
    action: "downloadPdf",
    name:"Скачать PDF"
  },
  {
    icon:"mdi-content-copy",
    name:"Копировать документ",
    action:"copyDocument",
    divider:true,
    component:() => import('@/components/documents/copy-document.vue')
  },
  {
    icon:"mdi-file-document-edit-outline",
    name:"Скорректировать",
    action:"adjustDocument",
  },
  {
    icon:"mdi-file-document-remove-outline",
    name:"Аннулировать",
    action:"annulDocument",
  },
  {
    icon:"mdi-delete",
    name:"Удалить документ",
    action:"deleteDocument",
  },
  {
    icon:"mdi-account",
    name:"Изменить исполнителя",
    action:"changePerformer",
    divider:true,
    component:() => import('@/components/documents/change-performer.vue')
  },
  null,
  {
    icon:"mdi-tray-arrow-down",
    name:"Скачать xml",
    action:"downloadXml"
  },
  {
    icon:"mdi-cash-plus",
    name:"Платежное поручение",
    action:"attachPaymentDocument",
    component:() => import('@/components/documents/attach-payment-document.vue')
  },
  {
    icon:"mdi-pause",
    name:"Отложить",
    action:"changeDeferredStatus"
  },
]

const methods = {
  adjustDocument({id}){
    this.$store.dispatch('statistics/createCancellation', {id, type:'КС'}).then(res => {

      this.$snackbar({text:`Создана корректировка - ${res.data}`,color:"green", top:false, right: false})
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => {
      this.$snackbar({text:'Ошибка', color:"red", top:false, right: false, timeout:5000});
    })
  },
  annulDocument({id}){
    this.$store.dispatch('statistics/createCancellation', {id, type:'АН'}).then(res => {
      this.$snackbar({text:`Создано аннулирование - ${res.data}`,color:"green", top:false, right: false, timeout:5000})
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => {
      this.$snackbar({text:'Ошибка', color:"red", top:false, right: false});
    })
  },
  getActionIndex(actionId){
    const action = this.actions.find(i => i?.action === actionId)
    return this.actions.indexOf(action)
  },
  copyDocument({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  changePerformer({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  downloadXml({id}){
    this.$store.dispatch("statistics/getXml", id).then((res) => {
      downloadXml(res.data, getFilenameFromContentDisposition(res))
    });
  },
  downloadPdf({id}){
    this.$store.dispatch('statistics/downloadPdf', id).then((res) => {
      const name = getFilenameFromContentDisposition(res)
      downloadFile({data:res.data, type:'application/pdf', name})
    }).catch(() =>this.$error())
  },
  attachPaymentDocument({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  changeDeferredStatus({id,item}){
    this.$store.dispatch('documents/changeDeferredStatus', {
      documentId: id,
      status: !item.is_deferred
    }).then(() => {
      this.$success("Статус изменен");
      this.$store.commit('experiment/SET_USER_FREE', 0)
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => this.$error())
  },
  deleteDocument({id, item}){
    const value = !item.is_trash
    this.$store.dispatch('statistics/toTrash',{id, value} ).then(() => {
      this.$success("Документ удален")
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => this.$error())
  }

}

export {actions, methods}
