<template>
  <journal-goods-list
    :id="id"
    :show.sync="isShow"
    :headers="headers"
    :title="title"
    :wares="items"
  >
    <template #header>
      <goods-list-header
        :declaration="declaration"
        :wares="items"
      />
    </template>
  </journal-goods-list>
</template>
<script>
import JournalGoodsList from "@/components/journal/journal-goods-list.vue";
import GoodsListHeader from "@/components/statistics/journal/goods-list-header.vue";
import {getCostString, getGrossWeightString} from "@/helpers/text";
import moment from "moment/moment";
import JournalGoodsListMixin from "@/mixins/journal-goods-list.mixin";


export default {
  components:{
    GoodsListHeader,
    JournalGoodsList
  },
  mixins:[JournalGoodsListMixin],
  data(){
    return{
      declaration:{},
      items:[],
      headers:[
        {
          text:"№",
          value:"index"
        },
        {
          text:"Код товара",
          value:"tn_ved"
        },
        {
          text:"Наименование товара",
          value:"title"
        },
        {
          text:"Происх.",
          value:"origin_country_letter"
        },
        {
          text:"Нетто",
          value:"net_weight"
        },
        {
          text:"Брутто",
          value:"gross_weight"
        },
        {
          text:"Стоимость",
          value:"cost"
        },
        {
          text:"Дата отгрузки",
          value:"shipment_date"
        },
      ],
    }
  },
  methods:{
    setItems(wares){
      this.items = wares.map((item, idx) => {
        const {
          id,
          tn_ved,
          title,
          description,
          specific_description,
          net_weight,
          gross_weight,
          invoice_cost,
          invoice_currency_letter,
          origin_country_letter,
          shipment_date,
        } = item
        const cost = getCostString(invoice_cost, invoice_currency_letter)
        const description_combined = [description, specific_description].filter(v => v).join('; ')
        const grossWeight = getGrossWeightString(gross_weight)
        return {
          index:idx + 1,
          id,
          tn_ved,
          title,
          description_combined,
          origin_country_letter,
          net_weight,
          gross_weight:grossWeight,
          cost,
          shipment_date:shipment_date ? moment(shipment_date).format('DD.MM.YYYY') : ''
        }
      })
    },
    fetchDocument(){
      this.$store.dispatch(`statistics/getDocumentInfo`, this.id).then(res => {
        this.declaration = res.data
        this.setItems(this.declaration.wares)
      }).catch(this.errorHandler)
    },
  }
}
</script>
